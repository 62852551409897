const routes = [
  {
    path: "/Product/SiteController", 
    name: "SiteController",
    component: () =>
      import( "../views/Product/SiteController.vue"),
  },
  {
    path: "/Product/SEMS", 
    name: "SEMS",
    component: () =>
      import( "../views/Product/SEMS.vue"),
  },
  {
    path: "/Product/BMU", 
    name: "BMU",
    component: () =>
      import( "../views/Product/BMU.vue"),
  },
  {
    path: "/Product/BCU", 
    name: "BCU",
    component: () =>
      import( "../views/Product/BCU.vue"),
  },
  {
    path: "/NewsPage/newsDemo", 
    name: "newsDemo",
    component: () =>
      import( "../views/NewsPage/newsDemo.vue"),
  },
  {
    path: "/NewsPage/news-DLNonline", 
    name: "news-DLNonline",
    component: () =>
      import( "../views/NewsPage/news-DLNonline.vue"),
  },
];

export default routes;