<template>
  <div id="nav">
    <Header />
  </div>
  <router-view  />
  <!-- <router-view v-if="isRouterAlive"></router-view> -->
  <Footer /> 
</template>
<script>
// @ is an alias to /src
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  // provide() {
  //     return {
  //       reload: this.reload
  //     };
  //   },
  //   data() {
  //     return {
  //       isRouterAlive: true
  //     };
  //   },
  //   methods: {
  //     reload() {
  //       this.isRouterAlive = false;
  //       this.$nextTick(function() {
  //         this.isRouterAlive = true;
  //       });
  //     }
  //   }
};
</script>

<style lang="scss">
    @import "@/assets/css/index.scss";
    .hide {
    visibility: hidden;
    }
    .show {
    visibility: visible;
}
</style>

