function isMobile() {
    var userAgentInfo = navigator.userAgent;
    var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var mobile_flag = false;
    //userAgent
    for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                mobile_flag = true;
                break;
        }
    }
    var screen_width = window.screen.width;
    var screen_height = window.screen.height;
    //height*width
    if (screen_width > 325 && screen_height < 750) {
        mobile_flag = true;
    }
    return mobile_flag;
}

const deviceType = isMobile();
export default{
	deviceType
}