<template>
  <div>
    <div class="carousel-wrapper" @mouseover="carouselOver"  @mouseout="carouselOut">
        <!-- 图片容器 -->
        <div class="img-content">
          <!-- 
            这里初始化只加载第一张图片，用户点击或定时器再加载其他图片
            这种加载模式也可优化初始化页面加载速度
           -->
		   <div class=" imgstyle">
			   <transition-group tag="ul" class="ul" name="list">    
				   <li class="li1" v-for="(item,index) in defaultList" v-show="item.id === pos" :key="item.id">
					   <a>
						   <img :src="item.src" alt="">
					   </a>
				   </li>
			   </transition-group >
		   <!-- <a><img :src="imgSrc" class="imgstyle moveStyle"/></a> -->
		   </div>
			<div class="banner img_up" v-show="isshow === 0">
				<div  style="width: 100%; height: 80%;">
				  <div class="content">
					<div class="title">系统简介</div>
						<h2
						  class="banner-text text-top animated"
						  data-animation="flipInX"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: flipInX;
							animation-delay: 0s;
							color: #7F7F7F;
						  "
						>
						  成都忆能科技
						</h2>
						<p
						  class="banner-text2 animated"
						  data-animation="fadeInUp"
						  data-delay="2"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: fadeInUp;
							animation-delay: 0.4s;
							color: #7F7F7F;
						  "
						>
						  数字化+智慧分布式储能平台，共创绿色家园
						</p>
					<div class="banner-img"></div>
				  </div>
				</div>
			</div>
			<div class="banner img_up" v-show="isshow === 1">
				<div  style="width: 100%; height: 80%;">
				  <div class="content">
					<div class="title"></div>
						<h2
						  class="banner-text text-top animated"
						  data-animation="flipInX"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: flipInX;
							animation-delay: 0s;
						  "
						>
						  专业专注，安全高效
						</h2>
						<p
						  class="banner-text2 animated"
						  data-animation="fadeInUp"
						  data-delay="2"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: fadeInUp;
							animation-delay: 0.4s;
						  "
						>
						  深耕储能行业10年+，有强大的销售、开发、运营管理团队
						</p>
					<div class="banner-img"></div>
				  </div>
				</div>
			</div>
			<!--  -->
			<div class="banner img_up" v-show="isshow === 2">
				<div  style="width: 100%; height: 80%;">
				  <!-- margin-right 文字置右 -->
				  <div class="content" style="margin-left: 50%;">
					<div class="title"></div>
						<h2
						  class="banner-text text-top animated"
						  data-animation="flipInX"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: flipInX;
							animation-delay: 0s;
						  "
						>
						  智能创新
						</h2>
						<p
						  class="banner-text2 animated"
						  data-animation="fadeInUp"
						  data-delay="2"
						  animation-status="true"
						  style="
							visibility: visible;
							animation-name: fadeInUp;
							animation-delay: 0.4s;
						  "
						>
						  实时监控、大数据分析、灵活扩展
						</p>
					<div class="banner-img"></div>
				  </div>
				</div>
			</div>
			
        </div>
        <!-- 底部圆形小图标 -->
        <div class="indicator-content" ref="indicator">
          <ul class="indicator-list">
            <li class="indicator-item" v-for="(item, index) in defaultList" :key="item.id">
              <a class="indicator-icon" @mouseover="iconOver(index)" :class="index === pos ? 'active' : '' "></a>
            </li>
          </ul>
        </div>
        <!-- 上一张、下一张按钮 -->
        <div class="control-content">
          <div title="上一张" class="prev" @click="toggleImg('prev')">&lt;</div>
          <div title="下一张" class="next" @click="toggleImg('next')">&gt;</div>
        </div>
    </div>     
  </div>
</template>

<script>
	// 定义每个小图标的宽度
	const iconWidth = 30;
	export default {
	  /*
	  * 接收父组件的值,轮播图宽度、高度、相邻两张图片切换的间隔时间、轮播图路径数组
	  */
	  props: {
	    // width: {
	    //   type: Number,
	    //   default: 100
	    // },
	    // height: {
	    //   type: Number,
	    //   default: 25
	    // },
	    interval: {
	      type: Number,
	      default: 4000
	    },
	    defaultList: {
	      type: Array,
	      default: function () {
	          return []; 
	      }
	    }
	  },
	  data() { 
	  	return {
	      // defaultList: [
	      //   {id: 1, src: 'http://img12.360buyimg.com/da/jfs/t3172/29/7532815266/78514/96c6e177/58ba3348N479cafe1.jpg'},
	      //   {id: 2, src: 'http://img10.360buyimg.com/da/jfs/t5626/206/5387922315/163107/149fda50/595ef2caN399971af.jpg'},
	      //   {id: 3, src: 'http://img13.360buyimg.com/da/jfs/t6481/166/2269199700/58810/742f31c4/595f5a97Nc1170590.jpg'}
	      // ],
	      // 默认显示第一张图片，数组下标为0
	      pos: 0,
	      // 定时器
	      startmove: ''
	  	}
	  },
	  created() {
	    // 调用定时器
	    this.startmove = setInterval(this.bannerMove, this.interval);
	    // 动态计算底部小图标容器的宽度和水平居中
	    this.$nextTick(() => {
	      this.$refs.indicator.style.width = iconWidth * this.defaultList.length + 'px';  
	      this.$refs.indicator.style.marginLeft = -(iconWidth / 2) * this.defaultList.length + 'px';  
	    })
	  },
	  computed: {
	    // icon样式
	    iconClass() {
	      return '';
	    },
	    // 图片路径
	    imgSrc() {
	      return this.defaultList[this.pos].src;
	    },
		isshow() {
			return this.pos
		},
	  },
	  
	  beforeDestroy() {
	    // 组件销毁前,清除监听器
	    clearInterval(this.startmove);  
	  },
	  methods: {
	   // 图片上、下一张切换
	    toggleImg(type) {
	      if(type === 'prev'){
	        this.pos === 0 ? this.pos = this.defaultList.length - 1 : this.pos -- ;
	      }else{
	        this.pos === this.defaultList.length - 1 ? this.pos = 0 : this.pos ++ ;
	      }
	    },
	    // 小图标鼠标hover事件
	    iconOver(index) { 
	      if(isNaN(index)) return;
	      this.pos = index;
	    },
	    // 图片自动切换定时器方法
	    bannerMove() {
	      this.pos === this.defaultList.length - 1 ? this.pos = 0 : this.pos ++ ;
	    },
	    // 鼠标移入图片区域，清除定时器，防止和用户切换图片事件冲突
	    carouselOver() {
	      clearInterval(this.startmove);  
	    },
	     // 鼠标移出图片区域，开启定时器
	    carouselOut() {
	      this.startmove = setInterval(this.bannerMove, this.interval);
	    }
	  },
	  components: {
	   
	  }
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/css/carousel.scss';
	
	.imgstyle {
		width: calc(100vw -18px);
		height: 40vw;
		// opacity: 0;
	}
	.img_up {
		position: absolute;
		left: 0;
		top: 0;
	}
	.img_down {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -100;
	}
	.ul {
	    li {
	        position:absolute;
	        img {
	            width: calc(100vw -18px);
	            height: 40vw;
	        }
	    }
	}
	
	.list-enter {
		transform: translateX(100%);
	}
	.list-enter-to {  //实现图片轮播的过渡效果
		transform: translateX(0);
	}	
    .list-leave-active {
    	transition: all 1.5s ease;
    }
	.list-leave {
		transform: translateX(0)
	}
	.list-leave-to {
		transform: translateX(-100%)
	}
	
	.listR-enter {
		transform: translateX(-100%);
	}
	.listR-enter-to {  //实现图片轮播的过渡效果
		transform: translateX(0);
	}	
	.listR-leave-active {
		transition: all 1s ease;
	}
	.listR-leave {
		transform: translateX(0)
	}
	.listR-leave-to {
		transform: translateX(100%)
	}

</style>
