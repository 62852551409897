<template>
		<div class="banner" :class="backgroundUrl==='' ? 'pic2':backgroundUrl">
		  <!-- <Carousel
			type="card"
			  :autoplay="true"
			  :duration="2000"
			  :initIndex="0"
			  :direction="true"
			  directionMode="hover"
			  :directionSize="20"
			  directionColor="skyblue"
			  :indicator="true"
			  indicatorMode="always"
			  indicatorColor="white"
			  indicatorActiveColor="skyblue"
			  @before-moving="beforeMoving"
			  @after-moving="afterMoving"
			  >
			  <CarouselItem v-for="(item, index) in datas" :key="index" :idx="index">
				  <img  :src="item" />
			  </CarouselItem>
		  </Carousel> -->
		  
		<!-- <div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2)"> -->
		<div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0)">
		  <div class="content">
			<div class="title">系统简介</div>
			
			<h2
			  class="banner-text text-top animated"
			  data-animation="flipInX"
			  animation-status="true"
			  style="
				visibility: visible;
				animation-name: flipInX;
				animation-delay: 0s;
			  "
			>
			  成都忆能科技
			</h2>
			<p
			  class="banner-text2 animated"
			  data-animation="fadeInUp"
			  data-delay="2"
			  animation-status="true"
			  style="
				visibility: visible;
				animation-name: fadeInUp;
				animation-delay: 0.4s;
			  "
			>
			  涵盖世界范围的储能，让"能，更高效"
			</p>
			<div class="banner-img"></div>
		  </div>
		</div>
    </div>
</template>

<script>
	export default {
	  props:{
		  datas:{
			  type:Array,
			  required:true,
		  },	
	  },
	  data() {
	      return {
			backgroundUrl: "",
			index:0,
			// datas: [
			// 	'../../assets/images/banner/2.jpg',
			// 	'../../assets/images/banner/1.jpg',
			// ],
			}
		},
	  mounted () {
		this.currentUrl()
	  },
	  methods: {
		  currentUrl () {
		  //每三秒更换一次背景图地址
		  setInterval(this.comparison, 3000)
		},
		comparison () {
		//index、backgroundUrl 都是在return里设置的默认值index默认0，backgroundUrl 默认''
		  if (this.datas.length > 0) {
			let i = this.datas.length
			if (this.index >= i) {
			  this.index = 0
			}
			this.backgroundUrl = 'pic' + (this.index+1)
			// console.log("xxxxx",this.backgroundUrl ,)
			this.index = this.index + 1
		  }
		}
	  }

	}		
</script>
<style scoped>
.banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 480px;
  padding-top: 80px;
  background-color: #000;
  /* background: url(../../assets/images/banner/2.jpg) 20%; */
  background-size: 100% 100%;
}
.pic1 {
    background: url(../../assets/images/banner/1.jpg) 20%;
 }
.pic2 {
	background: url(../../assets/images/banner/2.jpg) 20%;
}
.pic3 {
	background: url(../../assets/images/banner/3.jpg) 20%;
}

</style>
