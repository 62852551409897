<template>
  <div id="scrollstart" class="h_header">
    <header
      class="header_nav"
      :class="{ 'header_nav-color': isShowBg }"
	  :key="once"
      id="header"
    >
      <div class="container">
        <a href="index.html" class="logo-panel">
          <img
            src="../../../src/assets/logo-yn.png"
            class="fl header_logo"
            alt="成都忆能科技"
          />
          <div>
            <div class="font">忆能科技</div>
            <div class="small-font">YI NENG</div>
          </div>
        </a>
        <img
          class="fr tab_img_w"
          src="../../../src/assets/nav.png"
          alt="导航"
          @click="changeNavBar()"
        />
        <ul class="tab_list fr clearfix hidden font_16 text_color" id="navList">
          <li
            v-for="(item, index) in headList"
            :key="index"
            class="fl nav menu"
            :id="item.id"
            :class="[index == active ? 'nav_active' : '']"
            @click="changeNav(index)"
			@mouseover="mouseoverOnMemu(index)"
			@mouseleave="mouseleaveOnMemu(index)"
          >
            <a :title="item.title">{{ item.title }}</a>
			<!-- 子菜单 -->
			<div style="background-color: #FFFFFF;width: 130px; margin-top: 22px;">
				<ul class="menu-sub" v-show="index === isShowSubMenu">
					  <li class="menu-sub-li" v-for="(item1,idx) in item.child" :key="idx" @click="changesubNav(index,item1,idx)">
						  <a class="section-link"   :title="item1.title">
							{{item1.title}}
						  </a>
					  </li>
				</ul>
			</div>
			
          </li>
          <li class="fl icon_nav">
            <img
              src="../../../src/assets/wechart.png"
              alt="微信扫码"
              class="ico"
            />
            <div class="down-panel ewm-panel">
              <h3 class="h4">微信扫码咨询</h3>
              <img class="mgt20" src="../../../src/assets/erweima.jpg" />
            </div>
          </li>
          <li class="fl icon_nav">
            <img src="../../../src/assets/phone.png" alt="" class="ico" />
            <div class="down-panel tel-panel">
              <p>陈经理： 19950160307</p>
			  <p>赵经理： 15892133193</p>
            </div>
          </li>
		  <li class="fl icon_nav to-cloud-icon">
			 <a href="http://app.yntech.top" target="_blank"><img src="../../../src/assets/manage.png" alt="" class="ico" /></a>
		  </li>
        </ul>
      </div>
    </header>
  </div>
</template>
<script>
// import {serviceInfo, getNav} from '~/api/main.js'
export default {
  props: {},
  data() {
    return {
	  once:0,
      active: 0,
	  isShowSubMenu:null,
      headList: [
        { title: "首页", id: "home", path: "/" },
		{ title: "产品&解决方案", id: "solution", path: "/Solution" ,'child':[
			{
				title:'产品中心',
				id:'product',
				path:'/Product'
			 },
			{
				title:'解决方案',
				id:'solution',
				path:'/Solution'
				
			}
			 ]
		},
        // { title: "产品中心", id: "product", path: "/Product" },
		{ title: "项目应用", id: "project", path: "/Project" },
		// { title: "资讯中心", id: "news", path: "/News" },
        { title: "关于忆能", path: "/About_us" ,'child':[
			{
				title:'公司简介',
				id:'aboutYN',
				path:'/About_us'
			 },
			// {
			// 	title:'资讯中心',
			// 	id:'news',
			// 	path:'/News'
				
			// },
			{
				title:'联系我们',
				id:'contace_us',
				path:'/Contace_us'
				
			}
			 ]},
		// { title: "联系我们", path: "/Contace_us" },
      ],
      isShowBg: false,
    };
  },
  methods: {
	forceRerender(){
			this.once +=1;
	},
	changesubNav(index,item1,idx) {
		//切换菜单时滚动到top
		// document.getElementById("scrollstart").scrollIntoView()
		// localStorage.setItem('routeIndex',e)
		this.active = -1;
		let path = this.headList[index].child[idx].path;
		this.$router.push({ path: path });
		// var target = document.getElementById("navList");
		// target.classList.add("hidden");
		
		// 点击之后关闭弹窗
		this.mouseleaveOnMemu(index);
	},
    changeNav(e) {
	  if (this.headList[e].hasOwnProperty('child')){
		return;
	  }
	  //切换菜单时滚动到top
	  document.getElementById("scrollstart").scrollIntoView()
      localStorage.setItem('routeIndex',e)
      this.active = e;
      let path = this.headList[e].path;
      this.$router.push({ path: path });
      var target = document.getElementById("navList");
      target.classList.add("hidden");
    },
    changeNavBar() {
	  //切换菜单时滚动到top
	  document.getElementById("scrollstart").scrollIntoView()
      var flag = false;
      var target = document.getElementById("navList");
      var classList = target.classList;
      // console.log("classList", classList);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] == "hidden") {
          flag = true;
        }
      }
      if (flag) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    },
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 80) {
		this.isShowBg = true;
      } else {
        this.isShowBg = false;
		this.forceRerender();
      }
    },
	
	mouseoverOnMemu(index) {
		// 显示menu下拉菜单
		this.isShowSubMenu = index;
	},
	mouseleaveOnMemu(index) {
		// 关闭menu下拉菜单
		this.isShowSubMenu = -1;
	},
	
  },
  mounted() {
    let routeIndex = localStorage.getItem('routeIndex')
    if(routeIndex!==undefined){
      this.active = routeIndex
    }
    // console.log(this.active)
    
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.menu {
	text-align: center;
}
.menu-sub {
	// width: 130px;
	text-align: center;
	height: 100%;
}
.menu-sub-li {
	height: 40px;
	line-height: 40px;
}
.logo-panel {
  padding-top: 0px !important;
  height: 80px;
  display: flex;
  align-items: center;
  // color: #fff;
  color: #008e44;
  font-size: 19px;
  font-family: "PingFang SC";
  font-weight: 700;
  .font {
    line-height: 20px;
    margin-top: 6px;
	// margin-left: 10px;
    letter-spacing: 1px;
    // background-image: -webkit-linear-gradient(bottom, #ebfadd, #fff);
	background-image: -webkit-linear-gradient(bottom, #238941, #238941);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
	font-size: 19px;
  }
  .small-font {
    line-height: 20px;
    font-size: 15px;
    opacity: 0.7;
    letter-spacing: 3px;
    margin-left: 3px;
    // color: #ebfadd;
	color: #238941;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
}
.header_nav {
  cursor: pointer;
  // background: rgba(0, 0, 0, 0.0);
  background: #f5f5f5;
}
.header_nav-color {
  // background: #656565 ;  // #12232d
  background: #f5f5f5;
}
.header_nav .header_logo {
  display: block !important;
  width: 120px;
  padding-top: 3px;
  height: 40px;
  margin-right: 5px;
}
@media screen and (max-width: 750px) {
  .logo-panel {
    height: auto;
    padding-top: 0.09rem;
  }
  .header_nav .tab_list .nav {
    float: none;
    margin: 0px;
  }
}
</style>
