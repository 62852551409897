<template>
  <div class="i_footer">
    <footer id="footer" class="b_deep pab60 clearfix c_999 font_14">
      <div class="container mgt60">
        <div class="w470 fl" style="margin-left: 3%">
			<p class="font_14 mgt20 c_999">
				<a href="https://www.yntech.top/" target="_blank"
				  ><img
					class="mgl80 c_999 foot_logo"
					src="../../../src/assets/logo-yn.png"
					alt="成都忆能科技有限公司"
				/></a>
			</p>
          
			  <p class="font_14 mgt20 c_999">
				© 2018-2023&nbsp;<a
				  href="https://www.yntech.top/"
				  class="c_999"
				  target="_blank"
				  >成都忆能科技有限公司</a
				>&nbsp;版权所有
			  </p>
			  <p class="font_14 mgt10 c_999">
				<a href="http://beian.miit.gov.cn/" class="c_999" target="_blank"
				  >蜀ICP备2022028929号-1</a
				>
				<!-- |
				<a
				  href="../assets/images/index/roncoo_icp.jpg"
				  class="c_999"
				  target="_blank"
				  >ICP证粤B2-20210544</a
				> -->
			  </p>
			  <!-- <p class="mgt10 hidden_min c_999">
				<a
				  href="http://www.beian.gov.cn/portal/registerSystemInfo?COLLCC=1616986986&amp;recordcode=44010602005774"
				  class="c_999"
				  target="_blank"
				  >粤公网安备 44010602005774号</a
				>
			  </p> -->
        </div>
        <div class="w520 fl c_999 h24 min_footer">
          <p class="font_16 c_fff call_we">联系方式</p>
          <p class="mgt20">联系人：陈经理</p>
          <!-- <p>Q&nbsp;Q：451972987</p> -->
		  <p>邮&nbsp;箱：chengp@yntech.top</p>
          <!-- <p>手机号：18030416518（微信同号） |  公司固话：18030416518</p> -->
		  <p>手机号：19950160307 |  公司固话：028-87897798</p>
          <!-- <p></p> -->
          <p>工作时间：周一到周五（早上 9:00-12:00，下午 13:30-18:00）</p>
          <p>公司地址：成都市高新西区合作街道天骄路368号
          </p>
        </div>
        <div class="fl ewm_box">
          <p class="font_16 c_fff hidden_min">业务微信</p>
          <img
            class="mgt20 ewm_img"
            src="../../../src/assets/erweima.jpg"
          />
          <p class="c_999 mgt6">微信扫码咨询</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style scoped>
.foot_logo{
  width:74px;
}
</style>
<style lang="scss">
  @import '@/assets/css/index.scss';
</style>