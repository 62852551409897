import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Carousel from "v3-carousel"; // 引入
import animated from 'animate.css' // npm install animate.css --save安装，再引入
// 引入全局的设备类型判断
import commonPar from './assets/js/common.js'

const app = createApp(App)
	.use(store)
	.use(Carousel)
	.use(animated)
	.use(router);
// import BaiduMap from 'vue-baidu-map'
// .use(BaiduMap, {
//     ak: 'F6sGQzcVL7KXUevGEMTIwrgnPFxpxDBy'
// })

// 注册全局变量
app.config.globalProperties.$deviceType = commonPar.deviceType
// 手动挂在根节点
app.mount("#app");