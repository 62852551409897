import { createRouter,createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routeList = []
function importAll (r) {
	r.keys().forEach(key => {
		routeList.push(...r(key).default)
	})
}

importAll(require.context('../router',true,/\.routes\.js/))

const routes = [
  {
    path: "",
    name: "Home",
    component: Home,
  },
  {
    path: "/Solution",
    name: "Solution",
    component: () =>
      import( "../views/Solution.vue"),
  },
  {
    path: "/Product",
    name: "Product",
    component: () =>
      import( "../views/Product.vue"),
  },
  {
    path: "/News",
    name: "News",
    component: () =>
      import( "../views/News.vue"),
  },
  {
    path: "/About_us",
    name: "About_us",
    component: () =>
      import( "../views/About_us.vue"),
  },
  {
    path: "/Contace_us",
    name: "Contace_us",
    component: () =>
      import( "../views/Contace_us.vue"),
  },
  {
    path: "/Project", 
    name: "Project",
    component: () =>
      import( "../views/Project.vue"),
  },
  // {
  //   path: "/NewsPage/newsDemo", 
  //   name: "newsDemo",
  //   component: () =>
  //     import( "../views/NewsPage/newsDemo.vue"),
  // },
  ...routeList
];

const router = createRouter({
  // 去掉url路径中的‘#’标志符
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

export default router;
