<template>
  <div>
	<Yncarousel :defaultList="imgsrcList"></Yncarousel>
	<!-- <Banner v-bind:datas="datas"></Banner> -->
    <div class="advantage clearfix pab60">
      <header class="mgt60">
        <p class="font_24 center font_b adv-title ">我们的优势</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
        <p class="center font_16 mgt20 container pad_lr50 adv-desc" style="font-family: 'Arial Normal', 'Arial';
			font-weight: 400;color: #555555;">
          深耕储能、技术领先、全球布局
        </p>
		<!-- 易用、稳定、功能齐全 -->
      </header>
      <div class="container mgt40 adv-bgc">
        <div class="clearfix adv-content">
          <div v-for="(item, index) in proList"
               :key="index"
               class="adv-box2 animated slow"
               :class="[
              index === 0 || index % 2 === 0 ? 'fadeInLeft' : 'fadeInRight',
            ]">
            <div class="box-img box-img1">
              <img :src="item.icon"
                   alt="" />
            </div>
            <div class="box-title">
              <p>{{ item.name }}</p>
            </div>
            <div class="box-desc">
              <p>{{ item.desc1 }}</p>
              <p>{{ item.desc2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------合作模式------ -->
    <div ref="scrollOne"
         class="clearfix pab60 hezhuo animated slow hide"
         :class="{ 'show fadeIn': showOne }">
      <header class="head center">
        <p class="h3">合作模式</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
        <p class="desc">解决方案、系统集成、运营管理</p>
      </header>
      <div class="container mgt40">
        <div class="cooperation_box fl"
             v-for="(item, index) in hezuoList"
             :key="index">
          <img :src="item.icon"
               alt="" />
          <p class="model_title center mgt30">{{ item.name }}</p>
          <p class="model_txt c_666">
            {{ item.desc1 }}
          </p>
        </div>
      </div>
    </div>
    <!-- -------应用行业及领域------ -->
    <div ref="scrollTwo"
         class="scene clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showTwo }">
      <header class="mgt60">
        <p class="font_24 center font_b sce-title">应用行业及领域</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
        <p class="center font_16 mgt20 pad_lr50 sce-desc">
          支持多种场景
        </p>
      </header>
      <div class="container mgt40 sce-bgc">
        <div class="clearfix sce-content">
          <div class="sce-box"
               :style="{ background: 'url(' + item.bg + ') no-repeat'}"
			   style="background-size:100% 100%;"
               v-for="(item, index) in linyuList"
			   @click="jump(index)"
               :key="index">
            <div  class="box-img">
              <img :src="item.icon"
                   alt="" />
            </div>
            <div class="box-title">
              <p>{{ item.name }}</p>
            </div>
            <div class="box-desc">
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------合作伙伴------ -->
    <!-- <div ref="scrollThree"
         class="partner clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showThree }">
      <header class="mgt60">
        <p class="font_24 center font_b par-title">合作伙伴</p>
      </header>
      <div class="container mgt40">
        <div class="clearfix par-content">
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/huoni.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/energport.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/shenghong.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/epc.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/Invenergy.png"
                   alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- -------权威资质认证------ -->
    <!-- <div ref="scrollFour"
         class="b_999 clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showFour }">
      <header class="mgt60">
        <p class="font_24 center font_b">权威资质认证，安全有效保障</p>
        <p class="center font_16 mgt20 pad_lr50">
          自主研发，专业技术服务，支持定制化开发
        </p>
      </header>
      <div class="container mgt80 flex_box"
           id="jqviewer1">
        <div class="show_big1 fl">
		  <img src="@/assets/images/zhengshu/gjgxjs.jpg"
               alt="计算机软件证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/L3060chuneng.jpg"
               alt="计算机软件证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/mbmu.jpg"
               alt="增值电信业务经营许可证" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/scada.jpg"
               alt="高新技术企业证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/SiteController.jpg"
               alt="中国商业联合会知识产权分会会员" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/ynems.jpg"
               alt="高新技术产品证书" />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Banner from '@/components/common/Banner.vue'
import Yncarousel from '@/components/common/carousel.vue'

export default {
  name: 'Home',
  components: {
    Banner,
	Yncarousel,
  },
  data() {
    return {
	imgsrcList:
	  [
		// {id: 3, src: require('@/assets/images/banner/3.jpg')},
		{id: 2, src: require('@/assets/images/banner/3.jpg')},
	    {id: 1, src: require('@/assets/images/banner/2.jpg')},
	    {id: 0, src: require('@/assets/images/banner/1.jpg')}
	  ],
	  datas: [
	  	'../assets/images/banner/2.jpg',
	  	'../assets/images/banner/1.jpg',
	  ],
      proList: [
        {
          name: '深耕储能',
          icon: require('@/assets/images/sysindex/icon_box.png'),
          desc1: '丰富的全球储能行业经验',
          desc2: '专注储能项目开发和运营',
        },
		{
		  name: '技术雄厚',
		  icon: require('@/assets/images/sysindex/icon_cross.png'),
		  desc1: '软、硬件自主开发',
		  desc2: '一流储能解决方案',
		},
        {
          name: '简单易用',
          icon: require('@/assets/images/sysindex/icon_easy.png'),
          desc1: '全流程监控管理',
          desc2: '轻松上手',
        },
        {
          name: '系统稳定',
          icon: require('@/assets/images/sysindex/icon_stable.png'),
          desc1: '400+MWH项目在线安全稳定运行',
          desc2: '',
        },
        // {
        //   name: '多端会务',
        //   icon: require(''),
        //   desc1: '支持电脑端、小程序、H5等',
        //   desc2: '随时随地即可参会',
        // },
        {
          name: '自定义方案',
          icon: require('@/assets/images/sysindex/icon_expand.png'),
          desc1: '多套方案供选择',
          desc2: '根据客户需求高度定制化',
        },
        {
          name: '覆盖全球',
          icon: require('@/assets/images/sysindex/icon_allword.png'),
          desc1: '产品遍布美国、加拿大、墨西哥、',
          desc2: '智利等国家',
        },
      ],
      hezuoList: [
		{
		  name: '解决方案',
		  icon: require('@/assets/images/index/fa.png'),
		  desc1:
		    '根据需求提供储能解决方案，包括系统架构方案设计及定制化功能开发、以及售后维护。',
		},
        {
          name: '系统集成',
          icon: require('@/assets/images/index/hezuo1.png'),
          desc1:
            '提供系统所需的软硬件产品及文档，提供系统设计、结构设计、电气设计整套集成方案，为项目最终上线运行验收负责。',
        },
        {
          name: '运营管理', 
          icon: require('@/assets/images/index/zy.png'),
          desc1:
            '提供储能集成的全方面运营管理和维护。',
        }
      ],
      linyuList: [
        // {
        //   name: '室内储能',
        //   icon: require('@/assets/images/sysindex/hangye/icon_first.png'),
        //   bg: require('@/assets/images/hezuo-1-1.png'),
        //   desc1: 'MEETING / PROMOTION',
        // },
        {
          name: '工商业储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_second.png'),
          bg: require('@/assets/images/hezuo-1-2.jpg'),
          desc1: 'SUMMIT / FORUM',
        },
        {
          name: '电网侧储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_third.png'),
          bg: require('@/assets/images/hezuo-3-1.png'),
          desc1: 'TRAINING / EDUCATION',
        },
        {
          name: '其它储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_fourth.png'),
          bg: require('@/assets/images/hezuo-4-1.jpg'),
          desc1: 'MEETING / CELEBRATION',
        },
      ],
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
    }
  },
  methods: {
	jump(indexNum) {
		switch(indexNum){
			case 0:
				localStorage.setItem("cec", "container-1");
				this.$router.push({ path: "/Product"});
				break;
			case 1:
				localStorage.setItem("cec", "container-2");
				this.$router.push({ path: "/Product"});
				break;
			case 2:
				localStorage.setItem("cec", "container-3");
				this.$router.push({ path: "/Product"});
				break;
			case 3:
				localStorage.setItem("cec", "container-4");
				this.$router.push({ path: "/Product"});
				break;
		}
	},
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let oneHeight = this.$refs.scrollOne.offsetHeight
      let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight
      // let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight
      // let fourHeight = this.$refs.scrollFour.offsetHeight + threeHeight
      if (scrollTop > oneHeight) {
        this.showOne = true
      }
      if (scrollTop > twoHeight) {
        this.showTwo = true
      }
      // if (scrollTop > threeHeight) {
      //   this.showThree = true
      // }
      // if (scrollTop > fourHeight) {
      //   this.showFour = true
      // }
    },
  },
  mounted() {
	window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
</style>
